import request from '@/utils/axios'
//详情
export function Detail (params) {
  return request({
    url: '/api/getHouseDetail',
    method: 'get',
    params
  })
}
//更新签名
export function upSign (data) {
  return request({
    url: '/api/updateSign',
    method: 'post',
    data
  })
}

// 获取修改后的合同
export function getContract (params) {
  return request({
    url: '/api/getContractUuid',
    method: 'get',
    params
  })
}

//查询签名
export function getSign (params) {
  return request({
    url: '/api/getSign',
    method: 'get',
    params
  })
}

// 合同修改
export function Editor (data) {
  return request({
    url: '/api/customizeContract',
    method: 'post',
    data
  })
}
import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=4e47a404&scoped=true&"
import script from "./sign.vue?vue&type=script&lang=js&"
export * from "./sign.vue?vue&type=script&lang=js&"
import style0 from "./sign.vue?vue&type=style&index=0&id=4e47a404&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e47a404",
  null
  
)

export default component.exports